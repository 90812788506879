angular.module("app")
    .controller("paymentMethodCardEditController", ["$scope", "$log", "$state", "$stateParams", "$filter", "Notification", "paymentMethodService", "travelPolicyService", function ($scope, $log, $state, $stateParams, $filter, Notification, paymentMethodService, travelPolicyService) {
        var vm = this;
        vm.loading = true;
        vm.pageNotReady = true;
        vm.loadingForSave = false;
        vm.showPolicy = false;

        vm.paymentMethodReference = $stateParams.paymentMethodReference;
        vm.organisationReference = $stateParams.organisationReference;
        vm.enableEditCardDetails = false;
        vm.paymentMethod = undefined;

        vm.effectivePolicy = [];
        vm.availablePolicies = [];
        vm.hideDetail = false;

        (function init() {
            $log.info("initialised with reference id " + vm.paymentMethodReference);
            bindLoaderStateEvents();
            loadPaymentMethod().then(function () {
                loadTravelPolicies();
            });
        }());

        function loadTravelPolicies() {
            if (!_.isUndefined(vm.paymentMethod.travelPolicy)) {
                vm.effectivePolicy.push(vm.paymentMethod.travelPolicy);
            }

            return travelPolicyService.list(false)
                .then(function (results) {
                    $log.debug('policies %o', results);
                    if (results) {
                        results.forEach(function (policy) {
                            if (policy.isDefault && vm.effectivePolicy.length === 0) {
                                vm.effectivePolicy.push(policy);
                            } else {
                                if (_.isUndefined(vm.paymentMethod.travelPolicy) || policy.travelPolicyReference != vm.paymentMethod.travelPolicy.travelPolicyReference) {
                                    vm.availablePolicies.push(policy);
                                }
                            }
                        });
                    }

                    if (vm.effectivePolicy.length === 0) {
                        return travelPolicyService.getDefaultPolicy()
                            .then(function (result) {
                                if (!_.isUndefined(result)) {
                                    vm.effectivePolicy.push(result);
                                    vm.availablePolicies.push({});
                                }
                            });
                    }
                    if (vm.effectivePolicy.length > 0) {
                        vm.availablePolicies.push({});
                    }
                });
        }

        vm.collapseDetail = function () {
            vm.hideDetail = true;
            $log.debug('Hide detail ', vm.hideDetail);
        };

        function loadPaymentMethod() {
            vm.loading = true;
            return paymentMethodService.get(vm.organisationReference, vm.paymentMethodReference)
                .then(function (data) {
                    vm.paymentMethod = data;

                    vm.paymentMethod.card.expiryMonth = parseInt(vm.paymentMethod.card.expiryMonth);
                    vm.paymentMethod.card.expiryYear = parseInt(moment(vm.paymentMethod.card.expiryYear, 'YY').format('YYYY'));

                    initNotificationTypeOptions(vm.paymentMethod.card.notificationType);
                    if (vm.paymentMethod.travelPolicy) {
                        vm.showDefaultPolicy = false;
                    }

                }).finally(function () {
                    vm.loading = false;
                    vm.pageNotReady = false;
                });
        }

        vm.save = function () {


            angular.forEach($scope.form.$error.required, function (field) {
                field.$setTouched();
            });

            if (!$scope.form.$valid) return;


            vm.loadingForSave = true;

            var paymentMethod = angular.copy(vm.paymentMethod);
            paymentMethod.card.notificationType = buildNotificationType();
            paymentMethod.card.expiryYear = paymentMethod.card.expiryYear % 100;
            applyTravelPolicy(paymentMethod);

            return paymentMethodService.update(vm.organisationReference, paymentMethod)
                .then(function (results) {
                    $state.go("payment-methods");
                })
                .catch(function (resp) {
                    if (resp.data.messages) {
                        _.each(resp.data.messages, function (message) {
                            Notification.error({message: message, delay: 5000});
                        });
                    } else {
                        Notification.error({
                            message: 'There was a problem updating this card, please refresh and try again',
                            delay: 5000
                        });
                    }
                }).finally(function () {
                    vm.loadingForSave = false;
                });
        };

        function applyTravelPolicy(paymentMethod) {
            if (vm.effectivePolicy.length > 0) {
                var policy = vm.effectivePolicy[0];
                if (!policy.isDefault) {
                    paymentMethod.travelPolicy = policy;
                }
            } else {
                paymentMethod.travelPolicy = undefined;
            }
        }

        vm.effectivePolicyList = {
            accept: function () {
                if (vm.effectivePolicy.length >= 1) {
                    return false;
                } else {
                    return true;
                }
            }
        };


        vm.changeCcPassengerOption = function () {
            vm.ccPassenger = !vm.ccPassenger;
        };

        vm.dayInOrder = function (day) {
            return travelPolicyService.dayOfWeek(day.dayOfWeek);
        };

        /*vm.showHideDetail = function () {
         vm.showPolicy = !vm.showPolicy;
         };

         vm.overrideDefaultPolicy = function () {
         vm.showDefaultPolicy = false;
         };

         vm.unSelectPolicy = function () {
         vm.showDefaultPolicy = true;
         vm.paymentMethod.travelPolicy = undefined;
         };*/

        function initNotificationTypeOptions(notificationType) {
            var notificationTypeOptions = paymentMethodService.initPaymentMethodNotificationTypeOptions(notificationType);
            vm.notifyOrganisation = notificationTypeOptions.notifyOrganisation;
            vm.ccPassenger = notificationTypeOptions.ccPassenger;
        }

        function buildNotificationType() {
            return paymentMethodService.buildPaymentMethodNotificationType(vm.notifyOrganisation, vm.ccPassenger);
        }

        function bindLoaderStateEvents() {
            $scope.$on('loader_show', function () {
                vm.loading = true;
            });

            $scope.$on('loader_hide', function () {
                vm.loading = false;
            });
        }

    }])
;
